/**
 * encodes this string, taking extra care to make sure `@` aren't encoded
 * @returns {string}
 */
//eslint-disable-next-line no-extend-native
String.prototype.toUrlEncodedUsername = function() {
  return encodeURIComponent(this).replaceAll(/%40/g, '@')
}

/**
 * decodes this string. To be used for encoded usernames
 * @returns {string}
 */
//eslint-disable-next-line no-extend-native
String.prototype.fromUrlEncodedUsername = function() {
  return decodeURIComponent(this)
}
