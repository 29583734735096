import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import '../assets/sass/components/SessionRedirectModal.scss'

const SessionRedirectModal = ({ show }) => {
    if (!show) {
        return null;
    }

    return (
        <div id="sessionRedirectModal" className="modal">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="access-modal-content modal-content">
                    <div className="modal-body">
                        <p>User session detected.. redirecting.</p>
                        <div className="spinner-container d-flex justify-content-center">
                            <div className="loading-spinner">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionRedirectModal;
