import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState} from 'react';
import './SMSVerification.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowAltRight, faPhone} from "@fortawesome/free-solid-svg-icons";


const SMSVerification = (props) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    function submitStep (){
        alert("You entered: " + phoneNumber)
        setPhoneNumber('');
    }

    return(
        <div id="emailVerificationContent">
            <div id="header">
                <div id="headerText">SMS Verification</div>
            </div>

            <div id="conformation">
                <span id="input-text">
                    <FontAwesomeIcon icon={faPhone}/>&nbsp; Confirm your phone</span>

                <input type='tel'
                       id="phoneNumber"
                       className="form-control"
                       onChange={(event) => setPhoneNumber(event.target.value)}
                value={phoneNumber}/>

                <span id="belowBox">10 digit phone number</span>

            </div>

            <hr className="divider"/>
            <div id="buttons">

                    <a className="btn btn-secondary" id="backButton" disabled={false} href="/">Back</a>

                    <button className="btn button-next enabled" id="nextButton" disabled={false} onClick={() => submitStep()} type="submit">Next <FontAwesomeIcon icon={faLongArrowAltRight}/></button>

            </div>

        </div>
    );
};

export default SMSVerification;
