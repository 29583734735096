import '../assets/sass/components/Panel.scss'
export const Panel = ({children, title}) => {
    return (
        <div className="gaig-panel">
            {title ?
                <div className="row">
                    <h1>{title}</h1>
                </div>
                : null
            }
            <div className="row">
                {children}
            </div>
        </div>
    )
}
