import React from "react";
import "../assets/sass/components/Footer.scss"

const Footer = () => {
    return (
        <footer id="siteFooter" className="text-center">
            <p>&copy; {new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(new Date())} Great American Insurance Company. All Rights Reserved.<br/></p>
            <p>Great American Insurance Group Tower, 301 E. Fourth St. Cincinnati, OH 45202<br/></p>
          <p>The members of Great American Insurance Group are subsidiaries of <a href="https://www.afginc.com" target="_blank" rel="noreferrer">American Financial Group, Inc (AFG)</a>. AFG is a holding company based in Cincinnati, Ohio. It is a Fortune 500 Company whose common stock is listed on the New York Stock Exchange<br/></p>

            <p>
                <a className="footerLinks" href="https://www.greatamericaninsurancegroup.com/contact/legal-disclosures" target="_blank" rel="noreferrer">Legal Disclosures</a> |
                <a className="footerLinks" href="https://www.greatamericaninsurancegroup.com/contact/privacy" target="_blank" rel="noreferrer"> Privacy</a> |
                <a className="footerLinks" href="https://www.greatamericaninsurancegroup.com/contact/privacy/cookie-policy" target="_blank" rel="noreferrer"> Cookie Policy</a> |
                <a className="footerLinks" href="https://powerforms.docusign.net/0e4dc784-ec63-42a6-aa86-fc7b5c3620d0?env=na2&acct=f3f7268f-a85c-42ae-8ddb-ec3fdf82fc6c&accountId=f3f7268f-a85c-42ae-8ddb-ec3fdf82fc6c" target="_blank" rel="noreferrer"> Do Not Sell My Personal Information</a> |
                <a className="footerLinks" href="https://www.greatamericaninsurancegroup.com/docs/default-source/default-document-library/gaig-pcservice-marks.pdf?sfvrsn=c22874b1_26" target="_blank" rel="noreferrer"> Service Marks</a> |
                <a className="footerLinks" href="https://www.greatamericaninsurancegroup.com/contact/accessibility" target="_blank" rel="noreferrer"> Accessibility</a> |
                <a className="footerLinks" href="https://www.greatamericaninsurancegroup.com/site-map" target="_blank" rel="noreferrer"> Site Map</a> |
                <a className="footerLinks" href="https://gaig.sharepoint.com/sites/gateway" target="_blank" rel="noreferrer"> Gateway Employee Login</a>
            </p>
        </footer>
    )
}

export default Footer;
