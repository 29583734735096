import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

const Option = (props) => {

    return(
        <div className="Option">
            <div className="LeftContainer">
                <div className='ImageCell'>
                    <img className="OptionImage" src={props.image} style={{height: props.imageHeight, width:props.imageWidth }} alt={props.infoText}/>
                </div>
                <div className='TextCell'>
                    <p>
                        <b>{props.boldText}</b><br/>
                        {props.infoText}
                    </p>
                </div>
            </div>
            <div className="RightContainer">
                <a href="https://downloadmoreram.com">Set up</a>
            </div>
        </div>
    );
};

export default Option;
