import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faArrowRight, faCircleInfo, faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useOktaAuth} from "@okta/okta-react";
import "bootstrap/dist/css/bootstrap.min.css";
import React, {useEffect, useState} from "react";
import "../assets/sass/pages/InsuredSignIn.scss";
import AccessAgreementModal from "../components/AccessAgreementModal.js";
import LoadingSpinner from "../components/LoadingSpinner.js";
import SessionRedirectModal from "../components/SessionRedirectModal";
import {determineEnvironment, isFeatureEnabled} from "../config/FeatureFlagConfig";

const sendMessage = async (username) => {
  const res = await fetch('/api/v2/messages/' + username.toUrlEncodedUsername());
  return await res.json();
};

/** @enum envs to redirect to from the lower environments */
const lowerEnvs = Object.freeze({
  DEV: Symbol('DEV'),
  SB: Symbol('SB')
});

const query = new URLSearchParams(globalThis.location.search);
const env = determineEnvironment();

const InsuredSignInForm = () => {
  const [selectedLowerEnv, setSelectedLowerEnv] = useState(lowerEnvs[query.get('redirectEnv')?.toUpperCase() ?? 'DEV']);
  const {oktaAuth} = useOktaAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasSigninError, setHasSignInError] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showSessionRedirect, setShowSessionRedirect] = useState(false);
  const [showAccessAgreement, setShowAccessAgreement] = useState(false);

  useEffect(() => {
    oktaAuth.session.get()
      .then(({login}) => {
        if (login) {
          setShowSessionRedirect(true);
          if(env === 'dev') {
            // being explicit with the if/else here just in case the above if statement gets removed. We absolutely do not want to
            // redirect real prod users to either of these urls. They won't have access, and we will get tickets if we _default_ to either one.
            // that's also why we have a redirect outside the if statement - if for some reason all 3 of these branches fail, we default to whatever okta has
            if(selectedLowerEnv === lowerEnvs.SB) {
              window.location.href = 'https://portals-sandbox.gaig.com/auth/oidc/insured-sandbox/cb'
            } else if(selectedLowerEnv === lowerEnvs.DEV) {
              window.location.href = 'https://portals-dev.gaig.com/auth/oidc/insured-dev/cb'
            }
          } else {
            window.location.href = oktaAuth.options.redirectUri;
          }
        }
      }).catch(exception => {
      setHasSignInError(true);
      setLoginErrorText(exception.message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (action) => {
    action.preventDefault();
    setIsLoading(true);
    oktaAuth
      .idx.authenticate({
      username: username, password: password
    })
      .then((res) => {

        if (res.messages) {
          let containsError = res.messages.some(value => { return value.class === "ERROR"; });

          if (containsError) {
            setIsLoading(false);
            setHasSignInError(true);
            setLoginErrorText(<span>The information you have entered does not match our records.Please try again or call us for assistance at <a href="tel:8005431150">800-543-1150</a>.</span>);
            return false;

          }
        }
        sendMessage(username);
        // lower env okta can redirect to 1 of 2 different unqork urls
        if(env === 'dev') {
          // being explicit with the if/else here just in case the above if statement gets removed. We absolutely do not want to
          // redirect real prod users to either of these urls. They won't have access, and we will get tickets if we _default_ to either one.
          // that's also why we have a redirect outside the if statement - if for some reason all 3 of these branches fail, we default to whatever okta has
          if(selectedLowerEnv === lowerEnvs.SB) {
            window.location.href = 'https://portals-sandbox.gaig.com/auth/oidc/insured-sandbox/cb'
          } else if(selectedLowerEnv === lowerEnvs.DEV) {
            window.location.href = 'https://portals-dev.gaig.com/auth/oidc/insured-dev/cb'
          }
        } else {
          window.location.href = oktaAuth.options.redirectUri;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Found an error", err);
        setHasSignInError(true);
        if (err.message.includes("reenroll-custom-password-expiry")) {

          fetch('/api/v2/reset-password-url')
            .then((res) => {
              return res.text();
            }).then((urlRes) => {
            setLoginErrorText(<span>The password you have entered has expired. Please follow this <a
              href={urlRes}>link</a> to reset.</span>);
          });
        }

        // the http status code determines which error message we show the user
        else if (err.message === "tempToken") {
          setLoginErrorText(
            <span>Your account has not been verified.<br></br> Please complete your registration or<br></br> contact customer care at&nbsp;
              <a href="tel:8005431150">800-543-1150</a>
              &nbsp;<br></br>for further assistance.</span>);
          return;
        }
        if (err.xhr) {
          switch (err.xhr.status) {
            case 401:
              setLoginErrorText(<span>The information you have entered does not match our records.Please try again or call us for assistance at&nbsp;
                <a href="tel:8005431150">800-543-1150</a>.</span>);
              break;
            case 500:
            default:
              setLoginErrorText(<span>An error has occurred, please call us for assistance at&nbsp;
                <a
                  href="tel:8005431150">800-543-1150</a>.</span>);
              break;
          }
        } else if (typeof err === 'string') {
          setLoginErrorText(err);
        } else {
          setLoginErrorText(err.message);
        }
      });
  };
  // This handler will submit the form if the "Enter" key is pressed
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default Enter key behavior (form submission)
      handleSubmit(e); // Manually calls the above submit handler
    }
  };

  const validateForm = () => {
    return username?.trim().length > 0 && password?.trim().length > 0;
  };

  const resetPasswordUrl = (() => {
    if (isFeatureEnabled('newResetPassword')) {
      return '/request-password-reset';
    }
    let path = '';
    if (/dev/.test(window.location.href)) {
      path = 'https://registrationdev.td.afg/registration/forgetpassword.html?target=https://inspdev.td.afg/siteminderagent/forms/aip/branded_login.fcc';
    } else if (/uat/.test(window.location.href)) {
      path = 'https://registrationqa.gaig.com/registration/forgetpassword.html?target=https://inspdev.td.afg/siteminderagent/forms/aip/branded_login.fcc';
    } else {
      path = 'https://registration.gaig.com/registration/forgetpassword.html?target=https://myportal.gaig.com';
    }
    return path;
  })();

  const requestAccessUrl = isFeatureEnabled('selfRegistration') ? '/registration' : 'https://registration.gaig.com/registration/index.html?target=https://login-registration.gaig.com/insured';

  const selectLowerEnvRedirect = useDev => {
    setSelectedLowerEnv(useDev ? lowerEnvs.DEV : lowerEnvs.SB)
    const url = new URL(globalThis.location)
    url.searchParams.set('redirectEnv', useDev ? 'dev' : 'sb')
    // this modifies the query param without reloading the page
    globalThis.history.pushState({}, '', url.toString())
  }

  return (
    <div id="insuredSignIn" className="ga_login-app-middle-wrapper">
      <AccessAgreementModal
        show={showAccessAgreement}
        onClose={() => setShowAccessAgreement(false)}
      />
      <SessionRedirectModal
        show={showSessionRedirect}
      />
      {isLoading ? (
        <LoadingSpinner/>
      ) : (
        <div className="ga_login-app-middle-wrapper-container">
          <div className="ga_login-app-middle-wrapper-container-loginPanel">
            <div className="ga_loginPanelTile-container">
              <div className="ga_loginPanelTile-container-title">
                <h1>Policyholder Login</h1>
              </div>
            </div>
            <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
              <div className="ga_loginInput-wrapper">
                <label id="emailLabel" htmlFor="email">
                  Email
                  <span>*</span>
                </label>

                <div
                  className="ga_loginInput-wrapper-inputWrapper"
                  role="presentation"
                >
                  <FontAwesomeIcon className="inputIcon" icon={faEnvelope}/>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    aria-invalid="false"
                    aria-label="EMAIL"
                    value={username}
                    onChange={(element) => setUsername(element.target.value)}
                  />
                </div>
              </div>

              <div className="ga_loginInput-wrapper ga_loginInput-wrapper-padding">
                <div
                  id="form-group-password"
                  read-only="isDisabled(_component, data)"
                  unqorkio-form="unqorkioForm"
                  grid-row="gridRow"
                  grid-col="gridCol"
                  builder="builder"
                  options="options"
                >
                  <div>
                    <div>
                      <label htmlFor="password" className="">
                        PASSWORD{" "}
                        <span>*</span>
                      </label>
                      <div className="ga_loginInput-wrapper-inputWrapper">
                        <FontAwesomeIcon className="inputIcon"
                                         icon={faLock}
                        />
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className={'form-control'}
                          placeholder="Enter your password"
                          autoComplete="off"
                          value={password}
                          onChange={(element) => setPassword(element.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div id={'resetPasswordWrapper'}>
                    <a id={'resetPasswordLink'} href={resetPasswordUrl}>
                      <FontAwesomeIcon
                        className="inputCircleIcon"
                        icon={faCircleInfo}
                      />
                      Forgot Password?
                    </a>
                  </div>
                </div>
              </div>
              {hasSigninError ? (
                <p id="failedSignInMessage">{loginErrorText}</p>
              ) : null}
              <div className="ga_loginPanel-bottom">
                <div className="button-wrapper">
                  <input
                    id="submit-insured"
                    className="ga_Button ga_Button-primary btn-md"
                    type="submit"
                    value="Log In"
                    disabled={!validateForm()}
                  />
                  {env === 'dev' ? (
                    <div className="form-check" style={{marginLeft: '1rem'}}>
                      <label htmlFor={'useSandbox'} className="form-check-label">Go to Sandbox</label>
                      <input
                        id={'useSandbox'}
                        checked={selectedLowerEnv === lowerEnvs.SB}
                        type="checkbox"
                        className="form-check-input"
                        onChange={e => selectLowerEnvRedirect(!e.target.checked)}/>
                    </div>
                  ) : null}
                </div>
                <div id="textAccessAgreement">
                  <div className="ga_agrement-wrapper">
                    <p className="ga_access-text">
                      By clicking “Log in” you are accepting the terms of use in
                      the
                      <button
                        id="btnAccessAgreement"
                        name="btnAccessAgreement"
                        className="ga_Button link"
                        type="button"
                        onClick={() => setShowAccessAgreement(true)}
                      >
                        <span
                          id="accessagreement"
                          className="button-label"
                        >
                          Access Agreement
                        </span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="ga_login-app-middle-wrapper-container-signPanel">
            <div className="ga_signup-text">
              <div>
                <h2 className="ga_signup-text-header">
                  Don't have an account?
                </h2>
              </div>
              <div>
                <div>
                  <p className="ga_signup-text-description">
                    Insureds can request access to this platform by clicking
                    the button below.
                  </p>
                </div>
              </div>
            </div>
            <div className="ga_Signup-AccessRequest">
              <a
                href={requestAccessUrl}
                className="ga_Signup-AccessRequest-Button"
              >
                Request Access<FontAwesomeIcon
                className="insRequestAccessArrow"
                icon={faArrowRight}
                size="lg"
              />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

export default InsuredSignInForm;
