import React, {useState} from "react";

import WizardProgress from "./WizardProgress";
import '../assets/sass/components/WizardPanel.scss';

/**
 *
 * @param steps {{title: string, header: string | JSX.Element, subheader: string | JSX.Element | undefined, body: string | JSX.Element, isValid: () => boolean, submit: () => Promise<boolean> | undefined, nextButtonContents: string | JSX.Element | undefined}[]}
 * @param footer {string | JSX.Element | undefined}
 * @param currentStep {number}
 * @returns {JSX.Element}
 * @constructor
 */
const WizardPanel = ({steps, footer, currentStep}) => {

  const [stepIndex, setStepIndex] = useState(currentStep ?? 0);
  const [nextButtonContents] = useState(steps[stepIndex].nextButtonContents ?? 'Next');

  const [submitButtonContents] = useState(steps[stepIndex].nextButtonContents ?? 'Submit');


  const submitStep = async () => {
    if (await steps[stepIndex].submit()) {
      setStepIndex(stepIndex + 1);
    }
  };

  return (
    <div id="wizardPanel" className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-10 col-sm-12 col-xs-12 offset-sm-0 offset-lg-4 offset-md-1">
          <WizardProgress steps={steps} index={stepIndex}/>
          <div className="box-shadow gaig-panel">
            <h1 className="panel-header">{steps[stepIndex].header}</h1>
            <p className="panel-subheader">{steps[stepIndex].subheader}</p>
            {steps[stepIndex].body}
            {
             steps[stepIndex].submit !== undefined ? (
               <>
                 <hr/>
                 <div className="buttonWrapper">
                   <button id="nextBtn" className={`ga_Button ga_Button-primary`} disabled={!steps[stepIndex].isValid()}
                           onClick={() => submitStep()} type="submit">
                     {(stepIndex === 0) ? nextButtonContents : submitButtonContents}
                   </button>
                 </div>
               </>
             ) : <div/>
            }
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WizardPanel;
