import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {Security} from '@okta/okta-react';
import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import Footer from "./components/Footer";
import Header from "./components/Header";
import {isFeatureEnabled} from "./config/FeatureFlagConfig";
import InsuredRegistration from "./pages/InsuredRegistration.jsx";
import AgentSignInForm from './pages/AgentSignInForm';
import Home from './pages/Home';
import InsuredSignInForm from './pages/InsuredSignInForm';
import {RequestPasswordReset} from "./pages/password/requestPasswordReset/RequestPasswordReset";
import ResetPasswordOld from "./pages/password/resetPassword/ResetPassword_old";
import WelcomePage from './pages/welcomepage/WelcomePage'
import SMSVerification from "./pages/smsverification/SMSVerification";
import ResetPassword from "./pages/password/resetPassword/ResetPassword";
import CreatePasswordModal from "./pages/password/createNewPassword/CreatePasswordModal";

export default function AppWithRouterAccess() {

  const onAuthRequired = () => { window.location.pathname = ('/login'); };
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.pathname = (toRelativeUrl(originalUri, window.location.origin));
  };
  const [config, setConfig] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
      fetch("/api/v2/oktaConfig")
        .then(res => res.json())
          .then(res => {
              const generalConfig = {onAuthRequired, pkce: true, issuer: res.issuer, useInteractionCodeFlow: true,}
              setConfig({
                  agent: new OktaAuth({...res.agent, ...generalConfig}),
                  insured: new OktaAuth({...res.insured, ...generalConfig})
              });
              setLoading(false);
          });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
      return null;
  } else {
      return (
          <div className="ga_login-app-container">
              <Header/>
              <Security oktaAuth={config.agent} restoreOriginalUri={restoreOriginalUri}>
                  <Route path='/' exact={true} component={Home}/>
                  <Route path='/agent' exact={true} component={AgentSignInForm}/>
                  <Route path='/registration' exact={true} component={InsuredRegistration}/>
                  <Route path={'/welcome'} exact={true} component={() => <WelcomePage username="David"/>}/>
                  <Route path={'/verify'} exact={true} component={() => <SMSVerification/>}/>
                  <Route path={'/password'} exact={true} component={() => <CreatePasswordModal/>}/>
                  <Route path="/password/reset/:token" component={isFeatureEnabled('newResetPassword') ? ResetPassword : ResetPasswordOld} />
                  <Route path={'/request-password-reset'} component={RequestPasswordReset} />
              </Security>
              <Security oktaAuth={config.insured} restoreOriginalUri={restoreOriginalUri}>
                  <Route path='/insured' exact={true} component={InsuredSignInForm}/>
              </Security>
              <Footer/>
          </div>
      );
  }
};
