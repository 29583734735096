/** @type {Record<string, {dev: boolean, uat: boolean, prod: boolean}>} */
const featureMap = Object.freeze({
  selfRegistration: {
    dev: true,
    uat: true,
    prod: true
  },
  newResetPassword: {
    dev: true,
    uat: true,
    prod: true
  }
});

/**
 * checks which environment the browser is running in (dev, uat, or prod) and returns the result
 * - login-registration-sb: dev
 * - login-registration-dev: dev
 * - login-registration-uat: uat
 * - login-registration: prod
 * @return {'dev'| 'uat' | 'prod'} the environment determined. Will return `'prod'` if it cannot be determined
 */
export function determineEnvironment() {
  const host = globalThis.location.host;
  if(host.match(/^localhost|^login-registration-(dev|sb)/)) {
    return 'dev'
  } else if (host.match(/^login-registration-uat/)) {
    return 'uat'
  } else {
    return 'prod'
  }
}

/**
 * checks if the feature with the passed name is enabled according to the featureMap declared in the same file as this function.
 * This is done by checking the current url:
 * @param feature the name of the feature
 * @throws Error if no feature flag with the passed name is set
 *
 * @return true if the feature flag is true for the environment. Returns false if the flag is false
 */
export function isFeatureEnabled(feature) {
  if(!(feature in featureMap)) {
    throw Error(`${feature} is not configured in the enabled feature map`)
  }
  return featureMap[feature][determineEnvironment()]
}

