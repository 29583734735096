import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/sass/components/WizardPanel.scss';
import '../../assets/sass/components/ErrorPage.scss';
import {Panel} from "../../components/Panel";

const ErrorPage = ({ token }) => {
    const [requestEmail, setRequestEmail] = useState(false);
    const showRequestSentModal = { display: requestEmail ? 'block' : 'none' };
    const showRequestModal = { display: !requestEmail ? 'block' : 'none' };
    useEffect(() => {}, []);
    return (
        <div id="errorBox" className="container-fluid">
            <div id="confirmation-request-modal" style={showRequestModal}>
                <Panel>
                    <h1 id="errorBoxTitle">Confirmation Link Expired</h1>
                    <div id="error-text">
                        Your confirmation link has expired, please click the button below to request a new confirmation link.<br/>
                        <hr/>
                    </div>
                    <button id="resetTokenLink"
                            onClick={() => resetToken(token)}>
                        <div>Request a New Confirmation Link</div>
                    </button>
                </Panel>
            </div>
            <div id="confirmation-sent-modal" style={showRequestSentModal}>
                <Panel>
                    <h1 id="errorBoxTitle">Confirmation Sent</h1>
                    <div id="error-text">
                        A new confirmation link has been sent to your email. Click on the link in your email to complete registration.
                        <br/>
                        <br/>
                        If you don't see the email, check other inboxes such as your spam.
                    </div>
                </Panel>
            </div>
        </div>


    )

function resetToken(token) {
    fetch("/api/v2/token/" + token + "/reset")
            .then(res => {
                //no content
                    setRequestEmail(true);
                })
}
}
export default ErrorPage;
