import React from "react";
import {colors} from "./util/GaigColors";

/**
 * useful to allow for autocomplete with icons
 * @enum
 **/
export const Icons = Object.freeze({
  infoCircleOutline: 'infoCircleOutline',
  locationOutline: 'locationOutline',
  fileBlankOutline: 'fileBlankOutline',
  lockIcon: 'lockIcon'
});

/** @type {Record<string, Element>} */
const iconMapping = {
  infoCircleOutline: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.68451 16.444 4.10977 12.0425 4.086C7.64111 4.06246 4.04732 7.59876 4 12V12.172ZM14 17H11V13H10V11H13V15H14V17ZM13 9H11V7H13V9Z"/>
    </svg>
  ),
  locationOutline: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21C10.7369 19.9226 9.56619 18.7415 8.5 17.469C6.9 15.558 5 12.712 5 9.99999C4.99858 7.16754 6.70425 4.61338 9.32107 3.52939C11.9379 2.44539 14.9501 3.04523 16.952 5.04899C18.2685 6.3596 19.0059 8.14238 19 9.99999C19 12.712 17.1 15.558 15.5 17.469C14.4338 18.7415 13.2631 19.9226 12 21ZM12 4.99999C9.23995 5.0033 7.00331 7.23994 7 9.99999C7 11.166 7.527 13.185 10.035 16.186C10.6531 16.924 11.309 17.6297 12 18.3C12.691 17.6304 13.3472 16.9259 13.966 16.189C16.473 13.184 17 11.165 17 9.99999C16.9967 7.23994 14.7601 5.0033 12 4.99999ZM12 13C10.3431 13 9 11.6568 9 9.99999C9 8.34313 10.3431 6.99999 12 6.99999C13.6569 6.99999 15 8.34313 15 9.99999C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7957 13 12 13Z"/>
    </svg>
  ),
  fileBlankOutline: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2H13C13.0109 2.00047 13.0217 2.00249 13.032 2.006C13.0418 2.00902 13.0518 2.01103 13.062 2.012C13.1502 2.01765 13.2373 2.0348 13.321 2.063L13.349 2.072C13.3717 2.07968 13.3937 2.08904 13.415 2.1C13.5239 2.14842 13.6232 2.21618 13.708 2.3L19.708 8.3C19.7918 8.38479 19.8596 8.48406 19.908 8.593C19.918 8.615 19.925 8.638 19.933 8.661L19.942 8.687C19.9699 8.77039 19.9864 8.85718 19.991 8.945C19.9926 8.95418 19.9949 8.96322 19.998 8.972C19.9998 8.98122 20.0004 8.99062 20.0001 9V20C20.0001 21.1046 19.1046 22 18 22ZM6 4V20H18V10H13C12.4477 10 12 9.55228 12 9V4H6ZM14 5.414V8H16.586L14 5.414Z"/>
    </svg>
  ),
  lockIcon: (
    <svg viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 6.66667H13V4.7619C13 2.13333 10.76 0 8 0C5.24 0 3 2.13333 3 4.7619V6.66667H2C0.9 6.66667 0 7.52381 0 8.57143V18.0952C0 19.1429 0.9 20 2 20H14C15.1 20 16 19.1429 16 18.0952V8.57143C16 7.52381 15.1 6.66667 14 6.66667ZM8 15.2381C6.9 15.2381 6 14.381 6 13.3333C6 12.2857 6.9 11.4286 8 11.4286C9.1 11.4286 10 12.2857 10 13.3333C10 14.381 9.1 15.2381 8 15.2381ZM11.1 6.66667H4.9V4.7619C4.9 3.13333 6.29 1.80952 8 1.80952C9.71 1.80952 11.1 3.13333 11.1 4.7619V6.66667Z"/>
    </svg>
  )
};

/**
 *
 * @param props {{name: string, width?: number, height?: number, color?: string, className?: string, style?: Record<string, string>}}
 * @constructor
 */
export const Icon = ({name, width, height, color, className, style}) => {
  if (!(name in Icons)) {
    // icons should never halt the application functionality
    console.warn(name + ' is not a recognized icon name. Make sure it is in the iconMapping and Icons enum');
    return <></>;
  }
  width ??= 24;
  height ??= 24;
  color ??= colors.navy;
  className ??= '';
  style ??= {};

  style.fill = color;
  return React.cloneElement(iconMapping[name], {className, width, height, style});
};
