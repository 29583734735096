import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import '../assets/sass/components/AccessAgreementModal.scss'
import AccessAgreementBody from "./AccessAgreementBody.js";

const AccessAgreementModal = ({ show, onClose }) => {
    if (!show) {
        return null;
    }

    return (
        <div id="accessAgreement" className="modal" onClick={ onClose }>
            <div className="access-modal-dialog modal-dialog modal-dialog-scrollable" role="document" onClick={event => event.stopPropagation()}>
                <div className="access-modal-content modal-content">
                    <div className="modal-header">
                        <h1 className="access-modal-title modal-title">Access Agreement</h1>
                        <button type="button" className="close" aria-label="Close" onClick={ onClose }>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="access-modal-body modal-body">
                        <AccessAgreementBody />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={ onClose }>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccessAgreementModal;
