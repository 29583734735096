import {faArrowRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import '../../../assets/sass/components/buttons.scss';
import './RequestPasswordReset.scss'
import {isFeatureEnabled} from "../../../config/FeatureFlagConfig";
import {requestPasswordReset} from "../../../service/ResetPasswordService";
import {colors} from "../../../util/GaigColors";

/** @enum **/
const states = Object.freeze({
  LOADING: Symbol('LOADING'),
  ENTERING_USERNAME: Symbol('ENTERING_USERNAME'),
  EMAIL_SENT: Symbol('EMAIL_SENT')
})

export const RequestPasswordReset = () => {
  const [username, setUsername] = useState('')
  const [currentState, setCurrentState] = useState(states.ENTERING_USERNAME)
  useEffect(() => setCurrentState(states.ENTERING_USERNAME), [])
  if(!isFeatureEnabled('newResetPassword')) {
    return <></>
  }

  const submitReset = () => {
    setCurrentState(states.LOADING);
    requestPasswordReset(username)
      .then(() => setCurrentState(states.EMAIL_SENT))
  }

  const confirmEmail = (
    <div className="box-shadow gaig-panel">
      <h1 className="title">Confirm your email address</h1>
      <p>A verification link has been sent to <span className="value">{username}</span>. Please check your inbox
        and click on the link to reset your password</p>
    </div>
  )

  const form = (
    <div className="gaig-panel box-shadow">
      <h1>Reset Password</h1>
      <div className="row">
        <div className="col">Please, fill in your email and we will send you a link to reset your password.</div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="username" className="form-label small">your email</label>
          <input type="email" id="username" className="form-control" onChange={e => setUsername(e.target.value)}/>
        </div>
      </div>
      <hr/>
      <div className="row">
        <div className="col-6 offset-3">
          <button className="ga_Button ga_Button-primary" id={'submitReset'} onClick={submitReset}>Reset
            Password <FontAwesomeIcon icon={faArrowRight} color={'#0xffffff'}/></button>
        </div>
      </div>
    </div>
  )

  const loadingModal = (
    <div className="box-shadow gaig-panel">
      <h1 className="panel-header center">Loading...</h1>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <FontAwesomeIcon color={colors.navy} icon={faSpinner} className="fa-spin"
                           style={{width: '2em', height: '2em'}}/>
        </div>
      </div>
    </div>
  );

  const pickModalForState = () => {
    switch(currentState) {
      case states.ENTERING_USERNAME:
        return form;
      case states.EMAIL_SENT:
        return confirmEmail;
      case states.LOADING:
        return loadingModal;
      default:
        return loadingModal;
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-10 offset-md-1 offset-lg-4">
          {pickModalForState()}
        </div>
      </div>
    </div>
  );
};
