import {
  faArrowRight,
  faCheckCircle,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faSpinner,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {
  checkRequest,
  GoneError,
  InternalServerError,
  NotFoundError,
  sendResetPassword
} from "../../../service/ResetPasswordService";
import colors, {primaryShades} from "../../../util/GaigColors";
import '../../../assets/sass/components/buttons.scss';
import './resetPassword.scss';
import {Icon, Icons} from "../../../icons";


/** @enum */
const states = Object.freeze({
  LOADING: Symbol('LOADING'),
  NO_REQUEST_FOUND: Symbol('NO_REQUEST_FOUND'),
  PASSWORD_RESET_SUCCESS: Symbol('PASSWORD_RESET_SUCCESS'),
  PASSWORD_RESET_FAILURE: Symbol('PASSWORD_RESET_FAILURE'),
  REQUEST_EXPIRED: Symbol('REQUEST_EXPIRED'),
  ENTERING_PASSWORD: Symbol('ENTERING_PASSWORD'),
});

const ResetPassword = () => {
  //These are used to compare the passwords match.
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  // we don't want to show an error right when the user loads up the password page
  const [hasEnteredPassword, setHasEnteredPassword] = useState(false);
  const [lengthRequirement, setMatchesLengthRequirement] = useState(false);
  const [numberRequirement, setMatchesNumberRequirement] = useState(false);
  const [letterRequirement, setMatchesLetterRequirement] = useState(false);
  const [currentState, setCurrentState] = useState(states.LOADING);
  const {token} = useParams();

  const passwordInput = ({password, confirm}) => {
    setPassword(password);
    setConfirmPassword(confirm);
    setHasEnteredPassword(true);
    if (password !== confirm) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  };

  /**
   * checks the input password to make sure it conforms to our rules:
   * - 8+ characters
   * - upper and lower letters
   * - 1+ numbers
   */
  const processPassword = () => {
    let valid = true;
    if (password.length < 8) {
      valid = false;
      setMatchesLengthRequirement(false);
    } else {
      setMatchesLengthRequirement(true);
    }
    if (!/[0-9]/.test(password)) {
      valid = false;
      setMatchesNumberRequirement(false);
    } else {
      setMatchesNumberRequirement(true);
    }
    if (!/[A-Z]/.test(password) || !/[a-z]/.test(password)) {
      setMatchesLetterRequirement(false);
      valid = false;
    } else {
      setMatchesLetterRequirement(true);
    }
    setPasswordValid(valid);
  };

  // after each render (when password is changed), wait a bit and then check our password rules
  useEffect(() => {
    const debounce = setTimeout(processPassword, 100);
    return () => clearTimeout(debounce);
  });

  useEffect(() => {
      checkRequest(token)
        .then(res => {
          if (res.status === 'EXPIRED') {
            setCurrentState(states.REQUEST_EXPIRED);
          } else if (res.status === 'VALID') {
            setCurrentState(states.ENTERING_PASSWORD);
          }
        })
        .catch(e => {
          if (e instanceof NotFoundError) {
            setCurrentState(states.NO_REQUEST_FOUND);
          } else {
            setCurrentState(states.PASSWORD_RESET_FAILURE);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const submitPassword = (password) => {
    setCurrentState(states.LOADING);
    sendResetPassword(token, password)
      .then(() => setCurrentState(states.PASSWORD_RESET_SUCCESS))
      .catch(e => {
        if (e instanceof GoneError) {
          setCurrentState(states.REQUEST_EXPIRED);
        } else if (e instanceof NotFoundError) {
          setCurrentState(states.NO_REQUEST_FOUND);
        } else if (e instanceof InternalServerError) {
          setCurrentState(states.PASSWORD_RESET_FAILURE);
        }
      });
  };

  const createPasswordModal = (<div className="box-shadow gaig-panel">
    <h1>Define a Secure Password</h1>
    <div className="form-group">
      <label className={"password-text"} htmlFor="password"> New Password</label> <b className="required">*</b>
      <div id="passwordInputGroup"
           className={`input-group ${hasEnteredPassword && (!passwordsMatch || !passwordValid) ? 'error' : ''}`}>
        <span className="input-group-text"><Icon name={Icons.lockIcon} color={primaryShades.navy7} height={22} width={22}/></span>
        <input type={showPassword ? 'text' : 'password'}
               id="password"
               placeholder={'Enter new password'}
               className="form-control"
               onChange={(event) => passwordInput({
                 password: event.target.value,
                 confirm: confirmPassword
               })}/>
        <div id="togglePasswordView" className="btn"
             onClick={() => setShowPassword(!showPassword)}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
        </div>
      </div>
      <label className={"password-text"} htmlFor="password">Confirm Password</label> <b className="required">*</b>
      <div id="passwordInputGroup"
           className={`input-group ${hasEnteredPassword && (!passwordsMatch || !passwordValid) ? 'error' : ''}`}>
        <span className="input-group-text"><Icon name={Icons.lockIcon} color={primaryShades.navy7} height={22} width={22}/></span>
        <input type={showPassword ? 'text' : 'password'}
               id="password"
               placeholder={'Re-enter password'}
               className="form-control"
               onChange={(event) => passwordInput({
                 password: password,
                 confirm: event.target.value
               })}/>
        <div id="togglePasswordView" className="btn"
             onClick={() => setShowPassword(!showPassword)}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
        </div>
      </div>
      {!hasEnteredPassword || passwordsMatch ? null :
        <div id="policyNumberHelp" className="form-text form-tip error-text">
          <FontAwesomeIcon icon={faInfoCircle}/> Passwords do not match
        </div>
      }
      <div className="tooltip-footer">Avoid passwords that are easy to guess or used to login to other websites. We
        recommend
        using...
      </div>

      {/*rule markers*/}
      <div className="row mt-3">
        <div className="col-xs-12 rule">
          <FontAwesomeIcon className="rule-icon"
                           icon={lengthRequirement ? faCheckCircle : faTimesCircle}
                           color={lengthRequirement ? colors.success : null}/>
          <span style={{marginLeft: '1em'}}>Eight or more characters</span>
        </div>
        <div className="col-xs-12 rule">
          <FontAwesomeIcon className="rule-icon"
                           icon={letterRequirement ? faCheckCircle : faTimesCircle}
                           color={letterRequirement ? colors.success : null}/>
          <span style={{marginLeft: '1em'}}>Upper and lowercase letters</span>
        </div>
        <div className="col-xs-12 rule">
          <FontAwesomeIcon className="rule-icon"
                           icon={numberRequirement ? faCheckCircle : faTimesCircle}
                           color={numberRequirement ? colors.success : null}/>
          <span style={{marginLeft: '1em'}}>At least one number</span>
        </div>
      </div>
    </div>
    <hr/>
    <div className="row">
      <div className="col-6 offset-3">
        <button
            className="ga_Button ga_Button-primary"
          style={{width: '100%'}}
          disabled={!passwordValid || !passwordsMatch}
          onClick={() => submitPassword(password)}>
          Reset Password
        </button>
      </div>
    </div>
  </div>);

  const loadingModal = (
    <div className="box-shadow gaig-panel">
      <h1 className="panel-header center">Loading...</h1>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <FontAwesomeIcon color={colors.navy} icon={faSpinner} className="fa-spin"
                           style={{width: '2em', height: '2em'}}/>
        </div>
      </div>
    </div>
  );
  const successModal = (
    <div className="box-shadow gaig-panel">
      <div className="row d-flex justify-content-center mb-3">
        <FontAwesomeIcon icon={faCheckCircle} color={colors.success}
                         style={{width: '4em', height: '4em', padding: '0'}}/>
      </div>
      <div className="row center">
        <p>You have successfully reset your password, and you can now sign in with the new credentials you've
          created.</p>
      </div>
      <div className="row">
        <div className="col-8 offset-2">
          <a href="/insured" className="ga_Button ga_Button-primary">Log In <FontAwesomeIcon icon={faArrowRight}/></a>
        </div>
      </div>
    </div>
  );

  const expiredModal = (
    <div className="box-shadow gaig-panel" id={'linkExpired'}>
      <h1 className={'center'}>Password Reset Link Expired</h1>
      <div className="row mt-2">
        <div className="col center">Your password reset link has expired, please click the button below to request a new
          password reset link.
        </div>
      </div>
      <div className="row">
        <div className="col-6 offset-3">
          <Link to={'/request-password-reset'} className={'ga_Button ga_Button-primary'}>Request a New Reset Link</Link>
        </div>
      </div>
    </div>
  );

  const failureModal = (
    <div className="box-shadow gaig-panel">
      <h1 className="center">Password Reset Unsuccessful</h1>
      <div className="row">
        <div className="col center">We're sorry, but your password reset attempt was unsuccessful. please <Link
          className={'try-again'} to={'/request-password-reset'}>click here to try again</Link>. If the issue persists,
          please contact our
          customer care team at <a className={'telephone'} href={'tel:+18005454269'}>800-545-4269</a> for further
          assistance.
        </div>

      </div>
    </div>
  );

  const pickModalForState = () => {
    switch (currentState) {
      case states.LOADING:
        return loadingModal;
      case states.PASSWORD_RESET_SUCCESS:
        return successModal;
      case states.PASSWORD_RESET_FAILURE:
        return failureModal;
      case states.NO_REQUEST_FOUND:
      case states.REQUEST_EXPIRED:
        return expiredModal;
      case states.ENTERING_PASSWORD:
        return createPasswordModal;
      default:
        return <></>;
    }
  };

  return (
    <div id={'resetPassword'} className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-10 offset-md-1 offset-lg-4">
          {
            pickModalForState()
          }
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
