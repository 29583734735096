import React from "react";

const WizardProgress = ({ steps, index }) => {

    const getCircles = () => {
        let circles = [];

        for (let i = 0; i < steps.length; i++) {
            // completed
            if (i < index) {
                circles.push(
                    <span key={i}>
                        <div className="circle done"></div>
                        <p className="checkpointTitle">{steps[i].title}</p>
                    </span>
                );
            // current
            } else if (i === index) {
                circles.push(
                    <span key={i}>
                        <div className="circle current"></div>
                        <p className="checkpointTitle">{steps[i].title}</p>
                    </span>
                );
            // todo
            } else {
                circles.push(
                    <span key={i}>
                        <div className="circle todo"></div>
                        <p className="checkpointTitle" style={{color: '#677D95'}}>{steps[i].title}</p>
                    </span>);
            }
        }
        return circles;
    }

    return (
        <div>
            <div className="wizard progress">
                <div className="wizard progress-bar" role="progressbar"
                     style={{width: `${100 * index / (steps.length - 1)}%`}}
                     aria-valuemax="100" aria-valuemin="0"/>
            </div>
            <div className="checkpoints">
                {getCircles()}
            </div>
        </div>
    )
}

export default WizardProgress;
