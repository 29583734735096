import React from 'react';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  const { authState } = useOktaAuth();

  if (!authState) return null;


  return (
    <div className="ga_login-app-middle-wrapper">
      <Link className="button button--ujarak button--border-thin button--text-thick homeButton" to='/agent'>Agent</Link>
      <Link className="button button--ujarak button--border-thin button--text-thick homeButton" to='/insured'>Insured</Link>
    </div>
  );
};
export default Home;
