import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faCircleInfo, faLock, faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useOktaAuth} from "@okta/okta-react";
import "bootstrap/dist/css/bootstrap.min.css";
import React, {useEffect, useState} from "react";
import "../assets/css/index.css";
import "../assets/css/pages/AgentSignIn.css";
import "../assets/sass/pages/AgentSignIn.scss";
import AccessAgreementModal from "../components/AccessAgreementModal.js";
import LoadingSpinner from "../components/LoadingSpinner.js";
import SessionRedirectModal from "../components/SessionRedirectModal";
import {determineEnvironment} from "../config/FeatureFlagConfig";

const sendMessage = (username) => {
  return fetch("/api/v2/messages/" + username.toUrlEncodedUsername()).then(res => res.json());
};

/** @enum envs to redirect to from the lower environments */
const lowerEnvs = Object.freeze({
  DEV: Symbol('DEV'),
  SB: Symbol('SB')
});

const query = new URLSearchParams(globalThis.location.search);
const env = determineEnvironment();

const AgentSignInForms = () => {
  const [selectedLowerEnv, setSelectedLowerEnv] = useState(lowerEnvs[query.get('redirectEnv')?.toUpperCase() ?? 'DEV']);
  const {oktaAuth} = useOktaAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasSigninError, setHasSignInError] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showSessionRedirect, setShowSessionRedirect] = useState(false);
  const [showAccessAgreement, setShowAccessAgreement] = useState(false);

  useEffect(() => {
    oktaAuth.session.get()
      .then(function({login}) {
        if (login) {
          setShowSessionRedirect(true);
          if(env === 'dev') {
            // being explicit with the if/else here just in case the above if statement gets removed. We absolutely do not want to
            // redirect real prod users to either of these urls. They won't have access, and we will get tickets if we _default_ to either one.
            // that's also why we have a redirect outside the if statement - if for some reason all 3 of these branches fail, we default to whatever okta has
            if(selectedLowerEnv === lowerEnvs.SB) {
              window.location.href = 'https://portals-sandbox.gaig.com/auth/oidc/agent-sandbox/cb'
            } else if(selectedLowerEnv === lowerEnvs.DEV) {
              window.location.href = 'https://portals-dev.gaig.com/auth/oidc/agent-dev/cb'
            }
          } else {
            window.location.href = oktaAuth.options.redirectUri;
          }
        }
        return Promise.resolve();
      }).catch(exception => {
      setHasSignInError(true);
      setLoginErrorText(exception.message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (exception) => {
    exception.preventDefault();
    setIsLoading(true);
    oktaAuth
      .idx.authenticate({
      username: username,
      password: password
    })
      .then((res) => {
        if (res.messages) {
          let containsError = res.messages.some(
            value => { return value.class === "ERROR"; });
          if (containsError) {
            setIsLoading(false);
            setHasSignInError(true);
            setLoginErrorText(<span>The information you have entered does not match our records.Please try again or call us for assistance at <a href="tel:8005431150">800-543-1150</a>.</span>);
            return false;
          }
        }

        sendMessage(username);
        if(env === 'dev') {
          // being explicit with the if/else here just in case the above if statement gets removed. We absolutely do not want to
          // redirect real prod users to either of these urls. They won't have access, and we will get tickets if we _default_ to either one.
          // that's also why we have a redirect outside the if statement - if for some reason all 3 of these branches fail, we default to whatever okta has
          if(selectedLowerEnv === lowerEnvs.SB) {
            window.location.href = 'https://portals-sandbox.gaig.com/auth/oidc/agent-sandbox/cb'
          } else if(selectedLowerEnv === lowerEnvs.DEV) {
            window.location.href = 'https://portals-dev.gaig.com/auth/oidc/agent-dev/cb'
          }
        } else {
          window.location.href = oktaAuth.options.redirectUri;
        }

      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Found an error", err);
        setHasSignInError(true);
        if (err.message.includes("reenroll-custom-password-expiry")) {

          fetch('/api/v2/reset-password-url')
            .then((res) => {
              return res.text();
            }).then((urlRes) => {
            setLoginErrorText(<span>The password you have entered has expired. Please follow this <a
              href={urlRes}>link</a> to reset.</span>);
          });

        }
        // the http status code determines which error message we show the user
        else if (err.xhr) {
          switch (err.xhr.status) {
            case 401:
              setLoginErrorText(<span>The information you have entered does not match our records.Please try again or call us for assistance at&nbsp;
                <a href="tel:8005431150">800-543-1150</a>.</span>);
              break;
            case 500:
            default:
              setLoginErrorText(<span>An error has occurred, please call us for assistance at&nbsp;
                <a
                  href="tel:8005431150">800-543-1150</a>.</span>);
              break;
          }
        } else if (typeof err === 'string') {
          setLoginErrorText(err);
        } else {
          setLoginErrorText(err.message);
        }
      });
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default Enter key behavior (form submission)
      handleSubmit(e); // Manually calls the above submit handler
    }
  };

  const validateForm = () => {
    return username?.trim().length > 0 && password?.trim().length > 0;
  };

  const selectLowerEnvRedirect = useDev => {
    setSelectedLowerEnv(useDev ? lowerEnvs.DEV : lowerEnvs.SB)
    const url = new URL(globalThis.location)
    url.searchParams.set('redirectEnv', useDev ? 'dev' : 'sb')
    // this modifies the query param without reloading the page
    globalThis.history.pushState({}, '', url.toString())
  }

  return (
    <div id="agentSignIn" className="ga_login-app-middle-wrapper">
      <AccessAgreementModal
        show={showAccessAgreement}
        onClose={() => setShowAccessAgreement(false)}
      />
      <SessionRedirectModal
        show={showSessionRedirect}
      />
      {isLoading ? (
        <LoadingSpinner/>
      ) : (
        <div className="ga_login-app-middle-wrapper-container">
          <div className="ga_login-app-middle-wrapper-container-loginPanel">
            <div className="ga_loginPanelTile-container">
              <div className="ga_loginPanelTile-container-title">
                <h1>Log in</h1>
              </div>
            </div>

            <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
              <div className="ga_loginInput-wrapper">
                <label id="usernameLabel" htmlFor="username">
                  username
                  <span>*</span>
                </label>

                <div
                  className="ga_loginInput-wrapper-inputWrapper"
                  role="presentation"
                >
                  <FontAwesomeIcon
                    className="inputIcon"
                    icon={faEnvelope}/>
                  <input
                    className="form-control"
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Enter in your username"
                    aria-invalid="false"
                    aria-label="username"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                  />
                </div>
                <div className="ga_loginInput-wrapper-forgot-username">
                  <FontAwesomeIcon
                    className="inputCircleIcon"
                    icon={faCircleInfo}/>
                  <a
                    href="https://ims.afglink.com/sspr/public/ForgottenUsername?target=https%3A%2F%2Fagentportal.gaig.com"
                    className="reset-pa"
                    target="#">
                    Forgot Username?
                  </a>
                </div>
              </div>

              <div className="ga_loginInput-wrapper ga_loginInput-wrapper-padding">
                <div
                  id="form-group-password"
                  read-only="isDisabled(_component, data)"
                  unqorkio-form="unqorkioForm"
                  grid-row="gridRow"
                  grid-col="gridCol"
                  builder="builder"
                  options="options"
                >
                  <div>
                    <div>
                      <label htmlFor="password" className="agent-labels">
                        PASSWORD{" "}
                        <span>*</span>
                      </label>
                      <div className="ga_loginInput-wrapper-inputWrapper">
                        <FontAwesomeIcon
                          className="inputIcon"
                          icon={faLock}/>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder="Enter in your password"
                          autoComplete="off"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ga_loginInput-wrapper-forgot-username">
                    <FontAwesomeIcon
                      className="inputCircleIcon"
                      icon={faCircleInfo}/>
                    <a
                      href="https://ims.afglink.com/sspr/public/ForgottenPassword?target=https://agentportal.gaig.com"
                      className="reset-pa"
                      target="#">
                      Forgot Password?
                    </a>
                  </div>
                </div>
              </div>
              {hasSigninError ? (
                <p id="failedSignInMessage">{loginErrorText}</p>
              ) : null}

              <div className="ga_loginPanel-bottom">
                <div className="button-wrapper">
                  <input
                    id="submit"
                    className="ga_Button ga_Button-primary btn-md"
                    type="submit"
                    value="Log In"
                    disabled={!validateForm()}
                  />
                  {env === 'dev' ? (
                    <div className="form-check" style={{marginLeft: '1rem'}}>
                      <label htmlFor={'useSandbox'} className="form-check-label">Go to Sandbox</label>
                      <input
                        id={'useSandbox'}
                        checked={selectedLowerEnv === lowerEnvs.SB}
                        type="checkbox"
                        className="form-check-input"
                        onChange={e => selectLowerEnvRedirect(!e.target.checked)}/>
                    </div>
                  ) : null}
                </div>
                <div id="textAccessAgreement">
                  <div className="ga_agrement-wrapper">
                    <p className="ga_access-text">
                      By clicking “Log in” you are accepting the terms of use in
                      the
                      <button
                        id="btnAccessAgreement"
                        name="btnAccessAgreement"
                        className="ga_Button link"
                        type="button"
                        onClick={() => setShowAccessAgreement(true)}
                      >
                        <span
                          id="accessagreement"
                          className="button-label"
                        >
                          Access Agreement
                        </span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="ga_login-app-middle-wrapper-container-signPanel">
            <div className="ga_signup-text">
              <div>
                <h2 className="ga_signup-text-header">
                  Don't have an account?
                </h2>
              </div>
              <div>
                <div>
                  <p className="ga_signup-text-description">
                    Producers can request access to this platform by clicking
                    the button below.
                  </p>
                </div>
              </div>
            </div>
            <div className="ga_Signup-AccessRequest">
              <a
                href="https://ims.afglink.com/IDM/portal/cn/GuestContainerPage/Great%20American%20Insurance%20Account%20Registration"
                className="ga_Signup-AccessRequest-Button"
              >
                Request Access<FontAwesomeIcon
                className="agentRequestAccessArrow"
                icon={faLongArrowAltRight}
                size="lg"
              />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentSignInForms;
