/**
 * checks if a user exists in our database. This is determined by pulling the user and checking if the user id is null
 * @param username
 * @returns {Promise<boolean>} true if the user is in the aie db, false otherwise
 */
export async function isUserInAIE(username) {
  // some users have values that don't work in urls
  try {
    const res = await fetch(`/api/v4/users/${username.toUrlEncodedUsername()}`)
    if(res.status === 404) {
      return false;
    }
    const body = await res.json();
    return body.id !== null
  } catch (e) {
    console.error(e);
    throw new Error('Failed to fetch user')
  }
}

export async function validateUsername(username) {
  try {
    const response = await fetch(`/api/v4/users/validate/${username.toUrlEncodedUsername()}`)
    return await response.json()
  } catch (error) {
    return false;
  }
}

export function requestAccess(requestAccessInfo) {
  return fetch('/api/v4/users/request-access', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(requestAccessInfo),
  });
}

export function createUser(requestAccessInfo) {
  return fetch('/api/v4/users', {
    headers: {
      'content-type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(requestAccessInfo)
  });
}
