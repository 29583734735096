import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Panel } from "./Panel";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/sass/components/LoadingSpinner.scss'


const LoadingSpinner = () => {
    return (
        <div id="loadingPage">
            <Panel title={"Signing In"}>
                <h2>You are going to be redirected...</h2>
                <div>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                </div>
            </Panel>
        </div>
    );
}

export default LoadingSpinner;
