import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './WelcomePage.css'
import welcomeImage from './WelcomeSign.png'
import Option from "./Option";

import circleImage from './circle.png';
import emailImage from './email.png';
import mobilImage from './mobil.png';


const WelcomePage = (props) => {

    return(
            <div className="WelcomeModal">
                <div >
                    <div className="WelcomeModalHeader">
                        <img className="WelcomeImage" src={welcomeImage} alt="Welcome"/>
                        <div>
                            <h1 className="WelcomeTitle">Welcome, {props.username}</h1>
                            <p id="WelcomeTextArea">You are only a step away from viewing your account. You just need to activate another form of verification, this will make your account more secure. Please, choose at least one option below:</p>
                            <div className="OptionsContainer">
                                <Option image={circleImage} imageHeight="20px" imageWidth="20px" boldText="Mobile App Verification:" infoText="Use push notification sent to the mobile app"/>
                                <Option image={mobilImage} imageHeight="22px" imageWidth="14px" boldText="SMS Verification:" infoText="Send a code to my phone."/>
                                <Option image={emailImage} imageHeight="16px" imageWidth="20px" boldText="Email Verification:" infoText="Send a code to my email."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    );
};

export default WelcomePage;
