import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect} from 'react';
import '../../assets/sass/components/ErrorPage.scss';
import '../../assets/sass/components/WizardPanel.scss';
import {Panel} from "../../components/Panel";

const SuccessPage = () => {
  useEffect(() => {}, []);
  return (
    <div id="errorBox" className="container-fluid">
      <div>
        <Panel>
          <div>Congratulations!</div>
          <div className="gaig-panel">You are seeing this page because you have successfully reset your password. Please
            go to try to log in! Welcome.
          </div>
          <div className="d-flex justify-content-center">
            <button className="ga_Button ga_Button-primary" onClick={() => window.location.pathname = '/insured'}>Go To Login</button>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default SuccessPage;
