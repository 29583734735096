import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faLock, faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import '../../../assets/sass/components/WizardPanel.scss';
import '../../../assets/sass/pages/InsuredRegistration.scss';
import LoadingSpinner from "../../../components/LoadingSpinner";
import ErrorPage from "../../status/ErrorPage";
import SuccessPage from "../../status/SuccessPage";
import colors from '../../../util/GaigColors.js'


const ResetPasswordOld = ({ match }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [userPassword, setUserPassword] = useState('');
  const [confirmUserPassword, setConfirmUserPassword] = useState('');
  const [username, setUsername] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [passwordMatch, setPasswordsMatch] = useState(null)
  const [lengthRequirement, setMatchesLengthRequirement] = useState(false)
  const [numberRequirement, setMatchesNumberRequirement] = useState(false)
  const [letterRequirement, setMatchesLetterRequirement] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false);

  const {
    params: { token }
  } = match;

  useEffect(() => {
    fetch(`/api/v2/token/validate/${token}`)
      .then(res => res.status === 200 ? setUsername(atob(atob(token).split('/')[0])) : Promise.error(res.status))
      .catch(e => {
        setHasError(true);
      })
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (userPassword === confirmUserPassword) {
      return fetch(`/api/v2/users/${username.toUrlEncodedUsername()}/reset`, {
        method: 'POST',
        body: JSON.stringify({
          'password': userPassword
        }),
          headers: {
            'Content-Type': 'application/json'
          }
      })
        .catch(exception => {
          setIsLoading(false);
          setHasError(true);
        })
        .then(res => {
          setIsLoading(false);
          setIsSuccess(true);
        })
    } else {
      setIsLoading(false);
    }

  };

  const processPassword = (password) => {
    let valid = true
    if (password.length < 8) {
      valid = false
      setMatchesLengthRequirement(false)
    } else {
      setMatchesLengthRequirement(true)
    }
    if(!/[0-9]/.test(password)) {
      valid = false
      setMatchesNumberRequirement(false)
    } else {
      setMatchesNumberRequirement(true)
    }
    if(!/[A-Z]/.test(password) || !/[a-z]/.test(password)) {
      setMatchesLetterRequirement(false)
      valid = false
    } else {
      setMatchesLetterRequirement(true)
    }
    setPasswordValid(valid)
  };

  if (isLoading) {
    return (<div>
        <LoadingSpinner />
      </div>
    )
  } if (hasError) {
    return (<div>
      <ErrorPage token={token} />
    </div>)
  } if (isSuccess){
    return (<div>
      <SuccessPage/>
    </div>)
  }
  else
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-1"/>
          <div className="col-lg-4 col-md-10">
            <div className="box-shadow gaig-panel">
              <h1 className="panel-header" style={{marginBottom:"16px"}}>Define a secure password</h1>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <div id="passwordInputGroup" className="input-group">
                    <span className="input-group-text"><FontAwesomeIcon icon={faLock}/></span>
                    <input type={showPassword ? 'text' : 'password'}
                           id="password"
                           className="form-control"
                           placeholder="Enter a new password"
                           onChange={(event) => {
                             setUserPassword(event.target.value);
                             setPasswordsMatch(confirmUserPassword === event.target.value)
                             processPassword(event.target.value);
                           }
                           }/>
                    <div id="togglePasswordView" className="btn" onClick={() => setShowPassword(!showPassword)}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                    </div>
                  </div>
                  <label htmlFor="password">Confirm Password</label>
                  <div id="passwordInputGroup" className="input-group">
                    <span className="input-group-text"><FontAwesomeIcon icon={faLock}/></span>
                    <input type={showPassword ? 'text' : 'password'}
                           id="password"
                           className="form-control"
                           placeholder="Re-enter password"
                           onChange={(event) => {
                             setConfirmUserPassword(event.target.value)
                             setPasswordsMatch(userPassword === event.target.value)
                           }}/>
                    <div id="togglePasswordView" className="btn" onClick={() => setShowPassword(!showPassword)}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                    </div>
                  </div>
                  {passwordMatch ?
                    <div className="form-text form-tip error-text" style={{paddingTop:"8px", color:"white"}}>

                    </div> :
                    <div id="policyNumberHelp" className="form-text form-tip error-text" style={{paddingTop:"8px", color:"red"}}>
                      Passwords must match.
                    </div>
                  }

                  <div className="form-text">Avoid passwords that are easy to guess or used to login to other websites. We recommend using...</div>
                  {/*rule markers*/}
                  <div className="row mt-3" style={{borderBottom: '2px solid #CCD4DC', paddingBottom: '16px'}}>
                    <div className="col-xs-12 rule">
                      <FontAwesomeIcon className="rule-icon" icon={lengthRequirement ? faCheckCircle : faTimesCircle} color={lengthRequirement ? colors.success : null} />
                      <span style={{marginLeft: '1em'}}>Eight or more characters</span>
                    </div>
                    <div className="col-xs-12 rule">
                      <FontAwesomeIcon className="rule-icon" icon={letterRequirement ? faCheckCircle : faTimesCircle} color={letterRequirement ? colors.success : null} />
                      <span style={{marginLeft: '1em'}}>Upper and lowercase letters</span>
                    </div>
                    <div className="col-xs-12 rule">
                      <FontAwesomeIcon className="rule-icon" icon={numberRequirement ? faCheckCircle : faTimesCircle} color={numberRequirement ? colors.success : null} />
                      <span style={{marginLeft: '1em'}}>At least one number</span>
                    </div>
                  </div>
                  <div className="button-wrapper" style={{textAlign:"center", paddingTop: '16px'}}>
                    <input
                      id="submit-insured"
                      className="ga_Button ga_Button-primary btn-md"
                      type="submit"
                      value="Create Account"
                      disabled={!passwordValid || !passwordMatch}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ResetPasswordOld;
