import {
  faArrowRight,
  faCheckCircle,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faSpinner,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from 'react';
import {createUser, requestAccess} from "../../../service/UserService";
import colors, {primaryShades} from '../../../util/GaigColors';
import './CreatePasswordStyle.scss';
import '../../../assets/sass/components/buttons.scss';
import {Icon, Icons} from "../../../icons";

/** @enum */
const states = Object.freeze({
  VERIFIED: 'VERIFIED',
  EXPIRED: 'EXPIRED',
  RESENT: 'RESENT',
  PASSWORD_MODAL: 'PASSWORD_MODAL',
  FINISHING_REGISTRATION: 'FINISHING_REGISTRATION',
  REGISTRATION_ERROR: 'REGISTRATION_ERROR'
});

function CreatePasswordModal() {
  //These are used to compare the passwords match.
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  // we don't want to show an error right when the user loads up the password page
  const [hasEnteredPassword, setHasEnteredPassword] = useState(false);
  const [lengthRequirement, setMatchesLengthRequirement] = useState(false);
  const [numberRequirement, setMatchesNumberRequirement] = useState(false);
  const [letterRequirement, setMatchesLetterRequirement] = useState(false);
  const [decryptedToken, setDecryptedToken] = useState(null);
  const [currentState, setCurrentState] = useState(null);


  // after each render (when password is changed), wait a bit and then check our password rules
  useEffect(() => {
    const debounce = setTimeout(processPassword, 100);
    return () => clearTimeout(debounce);
  });

  // check to make sure the user has a token, or else we won't know who's setting the password
  useEffect(() => {
    const queryParmesan = new URLSearchParams(globalThis.location.search);
    const accessToken = queryParmesan.get('token');
    fetch('/api/v4/registration-details/' + accessToken)
      .then(res => res.json())
      .then(decrypted => {
        setDecryptedToken(decrypted);
        if (decrypted.expired) {
          setCurrentState(states.EXPIRED);
        } else if (decrypted) {
          setCurrentState(states.VERIFIED);
        }
      });
  }, []);

  const passwordInput = ({password, confirm}) => {
    setPassword(password);
    setConfirmPassword(confirm);
    if (password.toString().length !== 0 || confirm.toString().length !== 0) {
      setHasEnteredPassword(true);
    } else {
      setHasEnteredPassword(false);
    }
    if (password !== confirm) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  };

  /**
   * checks the input password to make sure it conforms to our rules:
   * - 8+ characters
   * - upper and lower letters
   * - 1+ numbers
   */
  const processPassword = () => {
    let valid = true;
    if (password.length < 8) {
      valid = false;
      setMatchesLengthRequirement(false);
    } else {
      setMatchesLengthRequirement(true);
    }
    if (!/[0-9]/.test(password)) {
      valid = false;
      setMatchesNumberRequirement(false);
    } else {
      setMatchesNumberRequirement(true);
    }
    if (!/[A-Z]/.test(password) || !/[a-z]/.test(password)) {
      setMatchesLetterRequirement(false);
      valid = false;
    } else {
      setMatchesLetterRequirement(true);
    }
    setPasswordValid(valid);
  };

  /** @returns {never} */
  const redirectToLoginPage = () => {
    globalThis.location.pathname = '/insured';
  };

  const submitUser = () => {
    const accessRequest = {
      password,
      ...decryptedToken.userInformation
    }
    setCurrentState(states.FINISHING_REGISTRATION);
    createUser(accessRequest)
      .then(res => res.status === 200 ? redirectToLoginPage() : Promise.reject(res.status))
      .catch(e => {
        console.error(e);
        setCurrentState(states.REGISTRATION_ERROR);
      });
  };

  const emailConfirmedModal = (
    <div id={'emailConfirmed'} className="box-shadow gaig-panel">
      <h1 className={'panel-header'}>
        <FontAwesomeIcon icon={faCheckCircle} color={colors.success}/>
      </h1>
      <p>Your email address has been successfully verified!</p>
      <button className="ga_Button ga_Button-primary" onClick={() => setCurrentState(states.PASSWORD_MODAL)}>Continue to
        Password Setup <FontAwesomeIcon icon={faArrowRight}/></button>
    </div>
  );

  const createPasswordModal = (<div className="box-shadow gaig-panel">
    <h1>Define a Secure Password</h1>
    <div className="form-group">
      <label className={"password-text"} htmlFor="password"> New Password</label> <b className="required">*</b>
      <div id="passwordInputGroup"
           className={`input-group ${hasEnteredPassword && (!passwordsMatch || !passwordValid) ? 'error' : ''}`}>
        <span className="input-group-text"><Icon name={Icons.lockIcon} color={primaryShades.navy7} height={22} width={22}/></span>
        <input type={showPassword ? 'text' : 'password'}
               id="password"
               placeholder="Enter new password"
               className="form-control"
               onChange={(event) => passwordInput({
                 password: event.target.value,
                 confirm: confirmPassword
               })}/>
        <div id="togglePasswordView" className="btn"
             onClick={() => setShowPassword(!showPassword)}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
        </div>
      </div>
      <label className={"password-text"} htmlFor="password">Confirm Password</label> <b className="required">*</b>
      <div id="passwordInputGroup"
           className={`input-group ${hasEnteredPassword && (!passwordsMatch || !passwordValid) ? 'error' : ''}`}>
        <span className="input-group-text"><Icon name={Icons.lockIcon} color={primaryShades.navy7} height={22} width={22}/></span>
        <input type={showPassword ? 'text' : 'password'}
               id="password"
               placeholder="Re-enter password"
               className="form-control"
               onChange={(event) => passwordInput({
                 password: password,
                 confirm: event.target.value
               })}/>
        <div id="togglePasswordView" className="btn"
             onClick={() => setShowPassword(!showPassword)}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
        </div>
      </div>
      {!hasEnteredPassword || passwordsMatch ? null :
        <div id="policyNumberHelp" className="form-text form-tip error-text">
          <FontAwesomeIcon icon={faInfoCircle}/> Passwords do not match
        </div>
      }
      <div className="tooltip-footer">Avoid passwords that are easy to guess or used to login to other
        websites.
        We recommend using...
      </div>

      {/*rule markers*/}
      <div className="row mt-3">
        <div className="col-xs-12 rule">
          <FontAwesomeIcon className="rule-icon fa-lg"
                           icon={lengthRequirement ? faCheckCircle : faTimesCircle}
                           color={lengthRequirement ? colors.success : null}/>
          <span className="password-rule-text">Eight or more characters</span>
        </div>
        <div className="col-xs-12 rule text-rule">
          <FontAwesomeIcon className="rule-icon fa-lg"
                           icon={letterRequirement ? faCheckCircle : faTimesCircle}
                           color={letterRequirement ? colors.success : null}/>
          <span className="password-rule-text">Upper and lowercase letters</span>
        </div>
        <div className="col-xs-12 rule text-rule">
          <FontAwesomeIcon className="rule-icon fa-lg"
                           icon={numberRequirement ? faCheckCircle : faTimesCircle}
                           color={numberRequirement ? colors.success : null}/>
          <span className="password-rule-text">At least one number</span>
        </div>
      </div>
    </div>
    <hr/>
    <footer>
      <div className="row">
        <div className="col-8 offset-2">
          <button
            className="ga_Button ga_Button-primary"
            disabled={!passwordValid || !passwordsMatch}
            style={{width: '100%'}}
            onClick={submitUser}>
            Create Account
          </button>
        </div>
      </div>
    </footer>
  </div>);

  const linkExpiredModal = (
    <div id="linkExpired" className="box-shadow gaig-panel">
      <h1 className="panel-header">Verification Link Expired</h1>
      <p>Your confirmation link has expired. Please click the button below to request a new confirmation link.</p>
      <button
        className="ga_Button ga_Button-primary"
        onClick={() => {
          requestAccess(decryptedToken.userInformation);
          setCurrentState(states.RESENT);
        }}>
        Request a New Verification Link
      </button>
    </div>
  );

  const resentLinkModal = (
    <div id="resentLink" className="box-shadow gaig-panel">
      <h1 className="panel-header">Verification Link Sent</h1>
      <p>A new confirmation link has been sent to your email. Click on the link in your email to complete
        registration.</p>
      <p>If you don't see the email, check other inboxes such as your spam</p>
    </div>
  );

  const finishingRegistrationModal = (
    <div id="finishingRegistration" className="box-shadow gaig-panel">
      <h1 className="panel-header">Finishing Up Your Registration</h1>
      <FontAwesomeIcon icon={faSpinner} className={'spinner'}/>
    </div>
  );

  const registrationFailureModal = (
    <div id="registrationFailure" className="box-shadow gaig-panel">
      <h1 className="panel-header">Failed to Complete Registration</h1>
      <p>Your registration could not be completed due to an error. Please contact customer care at <a href="tel:+18005431150">800-543-1150</a> for further assistance.
      </p>
    </div>
  );

  const pickModalForState = () => {
    switch (currentState) {
      case states.VERIFIED:
        return emailConfirmedModal;
      case states.PASSWORD_MODAL:
        return createPasswordModal;
      case states.EXPIRED:
        return linkExpiredModal;
      case states.RESENT:
        return resentLinkModal;
      case states.FINISHING_REGISTRATION:
        return finishingRegistrationModal;
      case states.REGISTRATION_ERROR:
        return registrationFailureModal;
      default:
        return <></>;
    }
  };

  return (
    <div id={'panel'} className="container-fluid">
      <div className="row">
        <div className="col-lg-4 col-md-1"/>
        <div className="col-lg-4 col-md-10">
          {
            pickModalForState()
          }
        </div>
        <div className="col-lg-4 col-md-1"/>
      </div>
    </div>
  );
}

export default CreatePasswordModal;
