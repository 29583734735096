export class NotFoundError extends Error {
}

export class GoneError extends Error {
}

export class InternalServerError extends Error {
}

/**
 * hits the backend to see if the passed request is valid
 * @param {string} requestId
 * @returns {Promise<{status: 'EXPIRED' | 'VALID'}>}
 * @throws NotFoundError if the endpoint returns 404 (no request found)
 * @throws InternalServerError if any other error occurs
 */
export async function checkRequest(requestId) {
  const res = await fetch('/api/v4/users/password/' + requestId);
  if (res.status === 404) {
    throw new NotFoundError();
  } else if (res.status !== 200) {
    throw new InternalServerError();
  }
  return res.json();
}

/**
 * hits the backend to send a password reset link to the user with the passed username
 * @param {string} username
 * @returns {Promise<void>}
 * @throws InternalServerError if the endpoint returns anything other than a 204 or a 404
 */
export async function requestPasswordReset(username) {
  const res = await fetch(`/api/v4/users/${username.toUrlEncodedUsername()}/password`);
  if (res.status !== 204 && res.status !== 404) {
    throw new InternalServerError();
  }
}

/**
 *
 * @param {string} requestId
 * @param {string} password
 * @returns {Promise<void>}

 * @throws NotFoundError if the endpoint returns 404 (no request found)
 * @throws GoneError if the endpoint returns 410 (request expired)
 * @throws InternalServerError if the endpoint returns anything other than 404, 410, or 204
 */
export async function sendResetPassword(requestId, password) {
  const headers = {'Content-Type': 'application/json'};
  const body = JSON.stringify({uuid: requestId, password});
  const res = await fetch('/api/v4/users/password', {
    method: 'POST',
    headers,
    body
  });
  if (res.status === 404) {
    throw new NotFoundError();
  } else if (res.status === 410) {
    throw new GoneError();
  } else if (res.status !== 204) {
    throw new InternalServerError();
  }

}

